var site = site || {};

(function ($, generic) {
  'use strict';

  Drupal.behaviors.salonTakeover = {
    attached: false,
    isProd: false,
    salonData: {
      logo: null,
      label: null,
      salonID: null,
      redirect: null,
      isSocialShoppe: 0,
      artistID: null,
      salonName: null,
      salonURL: null,
      isAcommerceEligible: null,
      rpcData: ''
    },
    getParameterByName: function (name, url) {
      if (!url) {
        url = window.location.href;
      }

      name = name.replace(/[\[\]]/g, '\\$&');
      var sanitizedValue = '';
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
      var results = regex.exec(url);

      if (!results) {
        return null;
      }

      if (!results[2]) {
        return '';
      }

      sanitizedValue = decodeURI(results[2].replace(/\+/g, ' '));
      return decodeURIComponent(sanitizedValue);
    },
    getSalonParams: function (cb) {
      var rpcData = this.salonData?.rpcData || {};

      this.salonData.salonID = rpcData.SALON_ID
        ? decodeURIComponent(rpcData.SALON_ID)
        : this.getParameterByName('SalonID')
        ? this.getParameterByName('SalonID')
        : null;

      this.salonData.salonURL = `shop.aveda.com/from-link/${this.salonData.salonID}?welcome=true`;

      this.salonData.doorID = rpcData.DOOR_ID
        ? decodeURIComponent(rpcData.DOOR_ID)
        : this.getParameterByName('door_id')
        ? this.getParameterByName('door_id')
        : null;

      this.salonData.label = this.getParameterByName('label')
        ? this.getParameterByName('label')
        : null;

      this.salonData.logo = this.getParameterByName('logo')
        ? this.getParameterByName('logo')
        : rpcData.NAV_LOGO
        ? decodeURIComponent(rpcData.NAV_LOGO)
        : '/media/images/profile/profile_full.jpg';

      this.salonData.isSocialShoppe =
        this.getParameterByName('socialshoppe') === '1'
          ? this.getParameterByName('socialshoppe')
          : self.sessionStorage.socialShoppe === '1'
          ? self.sessionStorage.socialShoppe
          : 0;

      this.salonData.artistID = this.getParameterByName('artistID')
        ? this.getParameterByName('artistID')
        : null;

      // Handle shop.aveda.com traffic/salon/vanity traffic
      if (this.salonData.isSocialShoppe) {
        if (!!this.getParameterByName('redirect')) {
          this.salonData.salonURL = this.getParameterByName('redirect');
          this.salonData.salonURL = this.salonData.salonURL + '?welcome=true';
        }
      }

      this.salonData.salonName = rpcData.DOORNAME
        ? decodeURIComponent(rpcData.DOORNAME)
        : this.salonData.label;

      this.salonData.isAcommerceEligible = rpcData.IS_ACOMMERCE_ELIGIBLE 
        ? decodeURIComponent(rpcData.IS_ACOMMERCE_ELIGIBLE)
        : 0; 

      if (cb !== undefined) {
        return cb(this.salonData);
      }

      return this.salonData;
    },
    setSessionData: function () {
      if(!this.salonData.isAcommerceEligible){
        ['SalonID', 'socialShoppe', 'logoImagePath', 'salonURL', 'salonName', 'label', 'artistID'].forEach(key => sessionStorage.removeItem(key));
        return;
      }

      if (window.sessionStorage && !$.isEmptyObject(this.salonData)) {
        if(!!this.getParameterByName("SalonID")){
          //Update session storage only when incoming SalonID in URL is different
          if(this.getParameterByName("SalonID") !== sessionStorage.getItem("SalonID")){
            sessionStorage.setItem('SalonID', this.salonData.salonID);
            sessionStorage.setItem('socialShoppe', this.salonData.isSocialShoppe);
            // Prevent default from over-writing logoImagePath session variable
            !!this.salonData.logo ? sessionStorage.setItem('logoImagePath', this.salonData.logo) : null;
            // Prevent default from over-writing salonURL session variable
            !!this.salonData.salonURL ? sessionStorage.setItem('salonURL', this.salonData.salonURL) : null;
            sessionStorage.setItem('salonName', this.salonData.salonName);
            sessionStorage.setItem('label', this.salonData.label);
            sessionStorage.setItem('artistID', this.salonData.artistID);
          }
        }
      } else {
        return false;
      }

      return true;
    },
    renderLabel: function (context) {
      var label = this.salonData.salonName ? this.salonData.salonName : '';
      var isArtist = !!this.getParameterByName('artistID');
      var labelHTML = '';
      var backTo = site?.translations?.elc_general?.back_to || 'Back to';

      // Check for for artist label
      if (isArtist || !!sessionStorage.getItem('label')) {
        label = !!this.salonData.label ? this.salonData.label : label;
        sessionStorage.setItem('label', label);
      }

      labelHTML = `<span class="js-salon-takeover__logo_label">${backTo} ${label}</span>`;

      // Append label after logo
      if (this.salonData.isSocialShoppe === '1') {
        var backLinkSrc = `https://${sessionStorage.getItem('salonURL')}`;
        var mobileLabelHTML = `<span class="js-salon-takeover__logo_label">&#8249; ${backTo} ${label}</span>`;
        var backLink = `<a class="js-mobile-salon-takeover__label hm-back-link" href="${backLinkSrc}">${mobileLabelHTML}</a>`;

        $('.js-site-header--v1').addClass('is-social-shoppe');

        //Append label, pc view
        $('a.site-header__menu-logo:first-child', context).append(labelHTML);
        //Append label, mobile view
        $('.js-site-header__utility--mobile', context).append(backLink);
      } else {
        $('.js-site-header--v1').removeClass('is-social-shoppe');
      }
    },
    createLogoLink: function (salonID, context) {
      var $header = $('.js-site-header', context);
      var $desktop = $header.find('.js-site-header__menu-list--desktop');
      var $mobile = $header.find('.js-site-header__utility-left');
      var dynamicURL = '';
      var isSocialShoppe = self.sessionStorage.getItem('socialShoppe');
      var gtfURL = `/locator/get_the_facts.tmpl?SalonID=${salonID}`;

      // Handle shop.aveda.com traffic/salon/vanity traffic
      if (isSocialShoppe === '1') {
        dynamicURL = `https://${self.sessionStorage.getItem('salonURL')}`;
      } else {
        // Not socialshoppe, goto get_the_facts
        dynamicURL = gtfURL;
      }

      // Create desktop logo
      $desktop
        .find('.js-site-header__menu-list-content')
        .filter(':first')
        .prepend(
          `<a class="site-header__menu-logo salon-takeover__logo" href="${dynamicURL}"><img /></a>`
        );

      // Create mobile logo, hide if socialshoppe == 1
      if (parseInt(this.salonData.isSocialShoppe) === 0) {
        $mobile
          .append(
            `<a class="site-header__utility-logo site-header__utility-item salon-takeover__logo" href="${dynamicURL}"><img /></a>`
          )
          .find('.site-header__utility-logo:first')
          .hide();
      }
    },
    getRpcData: function (salonID, cb) {
      var params = {
        salon_id: salonID
      };

      generic.jsonrpc.fetch({
        method: 'locator.getNavDataByDoor',
        params: [params],
        onSuccess: function (response) {
          var rpcData = response.getValue();
          cb(rpcData);
        }
      });
    },
    renderLogo: function (sessionImagePath, context) {
      var $logos = $('.salon-takeover__logo img', context);
      var logoURL = sessionImagePath.match(/(https\:\/\/|^\/.*)/g)
        ? sessionImagePath
        : `https://${sessionImagePath}`;
      // HM path, prod or staging environments
      var hmImgPath = `https://res.cloudinary.com/${
        this.isProd ? 'hausmartprod' : 'hausmartstaging'
      }/image/upload/salon-logo/${this.salonData.salonID}.png`;
      // Use HM image path
      if (this.salonData.isSocialShoppe === '1') {
        $logos.each(function () {
          $(this).attr('src', hmImgPath);
          $(this).on('error', function (e) {
            e.target.src = '/media/images/profile/profile_full.jpg';
            sessionStorage.setItem('logoImagePath', e.target.src);
          });
          sessionStorage.setItem('logoImagePath', hmImgPath);
        });

        return;
      }

      $logos.each(function () {
        $(this).attr('src', logoURL);
      });
    },
    updateVisibility: function () {
      // Toggle header/footer elements visibility via css
      $('body').addClass('salon-takeover');
    },
    applyLogo: function (salonID, context) {
      var self = this;
      var sessionImagePath = sessionStorage.getItem('logoImagePath');

      // Create salon logo html in header
      self.createLogoLink(salonID, context);

      // Update logo
      self.renderLogo(sessionImagePath, context);

      // Toggle header/footer elements visibility via css
      self.updateVisibility();

      $(document).trigger('salon:appliedLogo');
    },
    addProductsToCart: function () {
      var products = this.getParameterByName('products');
      var skus = products.split(',');
      const args = {
        INCREMENT: 1,
        action: 'add',
        itemType: 'cart',
        add_to_cart: 1,
        LAST_SOURCE: '/',
        skus: skus,
        QTY: 1
      };

      generic.checkout.cart.updateCart({
        params: args,
        onSuccess: function (r) {
          const parameters = new URL(window.location.href);
          parameters.searchParams.delete('products');
          window.location.href = parameters.toString();
        },
        onFailure: function (ss) {
          var errorObjectsArray = ss.getMessages();

          $(document).trigger('addToCart.failure', {
            errorObjects: errorObjectsArray
          });
        }
      });
    },
    init: function (context) {
      var self = this;
      var salonID = this.getParameterByName('SalonID')
        ? this.getParameterByName('SalonID')
        : sessionStorage.getItem('SalonID');

      if (!!salonID) {
        self.getRpcData(salonID, function (rpcd) {
          if (self.salonData.rpcData !== $.isEmptyObject(rpcd)) {
            self.salonData.rpcData = rpcd;

            // Save get params and render logo/label
            self.getSalonParams(function (sd) {
              // Save previous session only on subsequent pages
              if (!!!self.getParameterByName('SalonID')) {
                sd.isSocialShoppe =
                  sessionStorage.getItem('socialShoppe') === '1'
                    ? decodeURIComponent(sessionStorage.getItem('socialShoppe'))
                    : '0';
                sd.logo = !!sessionStorage.getItem('logoImagePath')
                  ? decodeURIComponent(sessionStorage.getItem('logoImagePath'))
                  : null;
                sd.label = !!sessionStorage.getItem('label')
                  ? decodeURIComponent(sessionStorage.getItem('label'))
                  : null;
                sd.salonURL = !!sessionStorage.getItem('salonURL')
                  ? decodeURIComponent(sessionStorage.getItem('salonURL'))
                  : null;
              }
              // Sets salonData global object
              self.salonData = sd;

              // Set params to session
              self.setSessionData();

              // Run logo processes if salon is eligible for commissions
              if(sd.isAcommerceEligible === '1'){
                self.applyLogo(salonID, context);
              }

              // Show logo label
              self.renderLabel(context);

              // Add products to cart
              if (self.getParameterByName('products')) {
                self.addProductsToCart();
              }

              $('.js-header', context).resize();
            });
          }
        });
      }
    },
    attach: function (context, settings) {
      var self = this;
      if (!self.attached) {
        self.isProd = !window.location.toString().includes('elcdev.net');
        self.init(context);
      }
      self.attached = true;
      /* Added to remove Grid Equal Height in Salon page */
      if ($('.js-getthefacts-container', context).length > 0) {
        $('.js-product-grid-item', context).each(function () {
          $(this).removeClass('js-grid-item').height('');
        });
      }
      // Removing the below class which initiates the sort function will result in null and removing all the products.
      // Sort implementation ticket ASMBLY4-73.
      if ($('.js-get-the-facts', context).length > 0) {
        $('.js-product-grid', context).removeClass('js-hair-quiz-match-reordering');
      }
    }
  };
})(jQuery, window.generic = window.generic || {});
